import React from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import Footer from '../../organisms/Footer/Footer';
import GoalsTable from '../../organisms/GoalsTable/GoalsTable';
import GoalsRewards from '../../organisms/GoalsRewards/GoalsRewards';
import { Helmet } from "react-helmet";

const topScorers = () => (
    <div>
        <Helmet>
            <title>Topschuttersstand | FC Los Tigres</title>
            <meta name="description" content="Topschuttersstand van zaalvoetbalclub FC Los Tigres." />
        </Helmet>
        <Navigation />
        <main className="container">
            <div className="standings-music goals-table">
                <GoalsTable />
                <GoalsRewards />
            </div>
        </main>
        <Footer />
    </div>
);

export default topScorers;