import React from 'react';
import Logo from '../../../assets/images/logo.png';
import Button from '../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const noMatch = () => (
    <main className="no-match">
        <Helmet>
            <title>404 | FC Los Tigres</title>
            <meta name="description" content="Oeps, pagina niet gevonden." />
        </Helmet>
        <img src={Logo} alt="FC Los Tigres badge" />
        <h1>Oeps, pagina niet gevonden</h1>
        <Link to="/"><Button text="Terug naar home" /></Link>
    </main>
);

export default noMatch;