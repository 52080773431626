import React from 'react';
//import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Calendar from './pages/Calendar/Calendar';
import Contact from './pages/Contact/Contact';
import TopScorers from './pages/TopScorers/TopScorers';
import Standings from './pages/Standings/Standings';
import Home from './pages/Home/Home';
import ScrollToTop from './ScrollToTop';
import NoMatch from './pages/NoMatch/NoMatch';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/kalender" component={Calendar} />
          <Route path="/contact" component={Contact} />
          <Route path="/topschuttersstand" component={TopScorers} />
          <Route path="/klassement" component={Standings} />
          <Route path="/" exact component={Home} />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
