import React, { Component } from 'react';

import Player from '../../molecules/Player/Player';
import Jorne from '../../../assets/images/jorne.jpg';
import Lenn from '../../../assets/images/lenn.jpg';
import Timo from '../../../assets/images/timo.jpg';
import Jochen from '../../../assets/images/jochen.jpg';
import Lennert from '../../../assets/images/lennert.jpg';
import Maarten from '../../../assets/images/maarten.jpg';
import Wouter from '../../../assets/images/wouter.jpg';
import Toby from '../../../assets/images/toby.jpg';
import Anthony from '../../../assets/images/anthony.jpg';
import SliderArrow from '../../../assets/images/slider-arrow.svg';

class Players extends Component {
    constructor(props) {
        super(props)
        this.state = {
            players: {
                0: {
                    image: <Jorne />,
                    number: 7,
                    firstName: "Jorne",
                    lastName: "Guldentops"
                },
                1: {
                    image: <Lenn />,
                    number: 99,
                    firstName: "Lenn",
                    lastName: "De Vos"
                },
                2: {
                    image: <Timo />,
                    number: 9,
                    firstName: "Timo",
                    lastName: "Meyvisch"
                },
                3: {
                    image: <Lennert />,
                    number: 10,
                    firstName: "Lennert",
                    lastName: "De Backer"
                },
                4: {
                    image: <Jochen />,
                    number: 14,
                    firstName: "Jochen",
                    lastName: "Meyvisch"
                },
                5: {
                    image: <Maarten />,
                    number: 2,
                    firstName: "Maarten",
                    lastName: "Willems"
                },
                6: {
                    image: <Toby />,
                    number: 11,
                    firstName: "Toby",
                    lastName: "Van Roy"
                },
                7: {
                    image: <Wouter />,
                    number: 6,
                    firstName: "Wouter",
                    lastName: "Thys"
                },
                8: {
                    image: <Anthony />,
                    number: 1,
                    firstName: "Anthony",
                    lastName: "Gabriels"
                },
            }
        }
    }

    componentDidMount() {
        document.body.classList.add("slides");
    }

    componentWillUnmount() {
        document.body.classList.remove("slides");
    }

    componentDidUpdate() {
        const players = document.getElementsByClassName("player");
        for(let i=0; i<players.length; i++)
        {
            players[i].classList.toggle("fade");
        }

        setTimeout(() => {
            for(let i=0; i<players.length; i++)
            {
                players[i].classList.toggle("fade");
            }
        }, 100);
    }

    renderNavigation() {
        return (
            <div className="slider-controls">
                <img id="slider-left" alt="SLide left button" src={SliderArrow} onClick={() => this.slideLeft()} />
                <img id="slider-right" alt="Slide right button" src={SliderArrow} onClick={() => this.slideRight()} />
            </div>
        )
    }

    slideLeft() {
        let newPlayers = {
            ...this.state.players
        };
        
        Object.keys(this.state.players).map((player, index) => {
            if(index === 0) {
                return newPlayers[8] = this.state.players[player];
            } else {
                return newPlayers[index-1] = this.state.players[player];
            }
        });
        
        this.setState({players: newPlayers});
    }

    slideRight() {
        let newPlayers = {
            ...this.state.players
        };

        Object.keys(this.state.players).map((player, index) => {
            if(index === 8) {
                return newPlayers[0] = this.state.players[player];
            } else {
                return newPlayers[index+1] = this.state.players[player];
            }
        });
        this.setState({players: newPlayers});
    }

    render () {
        const players = this.state.players;

        const mappedPlayers = Object.keys(players).map((player, index) => {
            let fullname = (players[player].firstName + " " + players[player].lastName);
            return (
              <Player 
                alt={fullname}
                src={players[player].image.type} 
                key={index} 
                number={players[player].number} 
                firstname={players[player].firstName} 
                lastname={players[player].lastName} />
            )
        });

        return (
            <div className="players">
                <h2>Spelers</h2>
                    {this.renderNavigation()}

                    <div className="player-collection">
                        {mappedPlayers}
                    </div>
                    
            </div>
        );
    }
}

export default Players;