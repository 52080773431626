import React, { Component } from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import MatchesPreview from '../../organisms/MatchesPreview/MatchesPreview';
import Players from '../../organisms/Players/Players';
import TeamsTable from '../../organisms/TeamsTable/TeamsTable';
import Footer from '../../organisms/Footer/Footer';
import spotifyIcon from '../../../assets/images/spotify-icon.png';
import Spinner from '../../atoms/Spinner/Spinner';
import backgroundWrapper from '../../../assets/images/large-logo.png';
import { Helmet } from "react-helmet";

class Home extends Component {
    state = {
        mobileNavigationOpen: false,
        loading: true,
    }

    hideSpinner = () => {
        this.setState({
          loading: false
        });
    };

    render () {
        const spotifyStyle = {
            backgroundPosition: 'center center',
            backgroundSize: '20%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: "url(" + spotifyIcon + ")"
        };

        const backgroundWrapperStyle = {
            backgroundImage: "url(" + backgroundWrapper + ")",
        };

        return (
            <div>
                <Helmet>
                    <title>FC Los Tigres</title>
                    <meta 
                        name="description" 
                        content="Officiële site FC Los Tigres. Zaalvoetbalclub die speelt in sporthal 't Stapveld te Berlaar." 
                    />
                </Helmet>
                <header className="home-header">
                    <Navigation />
                    <div className="background-wrapper" style={backgroundWrapperStyle}>
                        <div className="title">
                            <h1>FC Los Tigres</h1>
                        </div>
                        <MatchesPreview />
                    </div>
                </header>
                <main className="container">
                    <Players />
                    <div className="standings-music">
                        <TeamsTable />
                        <div className="spotify" style={spotifyStyle}>
                        {this.state.loading ? (
                            <Spinner />
                        ) : null}
                        <iframe 
                            title="Los Tigres Spotify playlist"
                            src="https://open.spotify.com/embed/playlist/3uifeXvHWcKU3ZJGCdhziM" 
                            width="100%" 
                            height="100%" 
                            frameBorder="0" 
                            allowtransparency="true" 
                            allow="encrypted-media"
                            onLoad={this.hideSpinner}
                            >
                        </iframe>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Home;