import React, { Component } from 'react';
import Button from '../../atoms/Button/Button';
import FutureMatch from '../../molecules/MatchInfo/FutureMatch';
import BlackBend from '../../../assets/images/black-bend.png';
import { Link } from 'react-router-dom';

class MatchesPreview extends Component {
    render () {
        return (
            <div className="matches-container">
                <img src={BlackBend} alt="Black background" className="black-bend" />
                <div className="container">
                    <div className="matches-preview">
                        <h2>Volgende matchen</h2>
                        <div className="matches">
                            <FutureMatch number="23" day="zondag" month="februari" time="17:30" team="'t Klikske" nextMatch />
                            <FutureMatch number="1" day="zondag" month="maart" time="18:30" team="JJ Koningshooikt" />
                            <FutureMatch number="8" day="zondag" month="maart" time="18:00" team="Dynamo Lier" />
                        </div>
                        <Link to="/kalender"><Button text="Volledige kalender" /></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default MatchesPreview;