import React, { Component } from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import Footer from '../../organisms/Footer/Footer';
import { Helmet } from "react-helmet";

class Standings extends Component {
    state = {
        teams: {
            team1: { name: "De Ronnies",  
                played: 10,  won: 7, draw: 1, lost: 2, points: 22 },
            team2: { name: "t Klikske", 
                played: 8,  won: 7, draw: 0, lost: 1, points: 21 },
            team3: { name: "JJ Koningshooikt",       
                played: 7,  won: 6, draw: 0, lost: 1, points: 18 },
            team4: { name: "Pink Panthers",        
                played: 8,  won: 6, draw: 1, lost: 1, points: 18  },
            team5: { name: "Dynamo Lier",     
                played: 9,  won: 6, draw: 1, lost: 2, points: 16  },
            team6: { name: "Forza Juno",               
                played: 11, won: 5, draw: 1, lost: 5, points: 16  },
            team7: { name: "La Tirette",       
                played: 8,  won: 3, draw: 3, lost: 2, points: 12 },
            team8: { name: "Lier United",        
                played: 10,  won: 4, draw: 0, lost: 6, points: 12  },
            team9: { name: "(nc)²",     
                played: 10,  won: 3, draw: 1, lost: 6, points: 10  },
            team10: { name: "Dynamo Gestel",       
                played: 9,  won: 3, draw: 2, lost: 4, points: 10  },
            team11: { name: "Hoekske Tanks",       
                played: 8,  won: 2, draw: 0, lost: 6, points: 6  },
            team12: { name: "Los Tigres",       
                played: 9, won: 1, draw: 0, lost: 8, points: 3  },
            team13: { name: "Ontmoeting",    
                played: 11,  won: 1, draw: 0, lost: 10, points: 3  },
        }
    }

    render() {
        const teams = Object.keys(this.state.teams).map((team, index) => {
            if(this.state.teams[team].name === "Los Tigres") {
                return (
                    <tr className="table-body-row los-tigres" key={this.state.teams[team].name}>
                        <td>{index+1}</td>
                        <td colSpan="3">{this.state.teams[team].name}</td>
                        <td>{this.state.teams[team].played}</td>
                        <td>{this.state.teams[team].won}</td>
                        <td>{this.state.teams[team].draw}</td>
                        <td>{this.state.teams[team].lost}</td>
                        <td>{this.state.teams[team].points}</td>
                    </tr>
                );
            } else {
                return (
                    <tr className="table-body-row" key={this.state.teams[team].name}>
                        <td>{index+1}</td>
                        <td colSpan="3">{this.state.teams[team].name}</td>
                        <td>{this.state.teams[team].played}</td>
                        <td>{this.state.teams[team].won}</td>
                        <td>{this.state.teams[team].draw}</td>
                        <td>{this.state.teams[team].lost}</td>
                        <td>{this.state.teams[team].points}</td>
                    </tr>
                );
            }
        })

        return (
            <div>
                <Helmet>
                    <title>Klassement | FC Los Tigres</title>
                    <meta name="description" content="Klassement van de zaalvoetbalcompetitie in sporthal 't Stapveld te Berlaar." />
                </Helmet>
                <Navigation />
                <main className="container">
                    <div className="teams-standings">
                        <h1>Klassement</h1>
                        <table>
                            <thead>
                                <tr className="table-head-row">
                                    <th></th>
                                    <th colSpan="3">Ploeg</th>
                                    <th className="truncate">Matchen</th>
                                    <th className="truncate">Winst</th>
                                    <th className="truncate">Gelijk</th>
                                    <th className="truncate">Verlies</th>
                                    <th className="points">Punten</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teams}
                            </tbody>
                        </table>
                    </div> 
                </main>
                <Footer />
            </div>
        );
    }
}

export default Standings;