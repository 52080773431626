import React, { Component } from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import Footer from '../../organisms/Footer/Footer';
import ContactForm from '../../organisms/ContactForm/ContactForm';
import MapsBackground from '../../../assets/images/maps-background.png';
import { Helmet } from "react-helmet";

class Contact extends Component {
    state = {
        loading: true
    }

    hideSpinner = () => {
        this.setState({
          loading: false
        });
    };

    showSpinner = () => {
        console.log("error");
        this.setState({
            loading: true
        });
    }

    render() {
        return (
            <div className="contact">
                <Helmet>
                    <title>Contact | FC Los Tigres</title>
                    <meta name="description" content="Vul het onderstaande formulier in en we contacteren je zo snel mogelijk in verband met je vraag." />
                </Helmet>
                <Navigation />
                <main className="container">
                    <ContactForm />
                    <div className="google-maps">
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                {this.state.loading ? (
                                <img id="maps-background" src={MapsBackground} alt="Google maps on load background" />
                                ) : null}
                                <iframe 
                                    title="'t Stapveld location on Google maps"
                                    width="100%" 
                                    height="100%" 
                                    id="gmap_canvas" 
                                    src="https://maps.google.com/maps?q=stapveld&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                                    frameBorder="0" 
                                    scrolling="no" 
                                    marginHeight="0" 
                                    marginWidth="0"
                                    onLoad={this.hideSpinner}
                                    >
                                </iframe>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Contact;