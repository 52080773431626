import React from 'react';

const player = (props) => (
    <div className="player">
        <img src={props.src} alt={props.alt} className="player-image" />
        <span className="player-number">{props.number}</span>
        <div className="player-name">
            <span>{props.firstname}</span>
            <span>{props.lastname}</span>
        </div>
    </div>
);

export default player;