import React from 'react';

const playedMatch = (props) => {
    let scoreClass = "match-score";
    let result = <div className="match-won"><span>W</span></div>;

    if(props.lost)
    {
        scoreClass = "match-score match-lost";
        result = <div className="match-loss"><span>V</span></div>;
    }

    return (
    <div className="match-info">
        <div className="info-top">
            <div className="info-date">
                <span className="number">{props.number}</span>
                <div className="date-text">
                    <span className="day">{props.day}</span>
                    <span className="month">{props.month}</span>
                </div>
            </div>
            {result}
        </div>
        <div className={scoreClass}>
            <p>{props.score}</p>
        </div> 
        <span className="team-name">{props.team}</span>
    </div>
    );
};

export default playedMatch;