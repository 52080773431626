import React from 'react';
import Beloningen from '../../../assets/images/beloningen.png';

const goalsRewards = () => (
    <div className="goals-rewards">
        <h2>Beloningen</h2>
        <p>Aantal gescoorde goals per match</p>
        <img className="rewards-image" src={Beloningen} alt="A reward list for every goal scored at the end of the season." />
    </div>
);

export default goalsRewards;