import React from 'react';

const futureMatch = (props) => {
    let nextMatch = null;
    let matchClass = "match-info";

    if(props.nextMatch)
    {
        nextMatch = <span className="next-match">Volgende match</span>;
        matchClass = "match-info info-next";
    }

    return (
    <div className={matchClass}>
        <div className="info-top">
            <div className="info-date">
                <span className="number">{props.number}</span>
                <div className="date-text">
                    <span className="day">{props.day}</span>
                    <span className="month">{props.month}</span>
                </div>
            </div>
            {nextMatch}
        </div>
        <div className="match-time">
            <p>{props.time}</p>
        </div>
        <span className="team-name">{props.team}</span>
    </div>
    );
};

export default futureMatch;