import React, { Component } from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import Arrow from '../../../assets/images/arrow.png';
import Footer from '../../organisms/Footer/Footer';
import PlayedMatch from '../../molecules/MatchInfo/PlayedMatch';
import FutureMatch from '../../molecules/MatchInfo/FutureMatch';
import { Helmet } from "react-helmet";

class Calendar extends Component {
    componentDidMount() {
        const arrowIcons = document.getElementsByClassName("arrow-icon");
        const monthHeaders = document.getElementsByClassName("month-header");
        const matches = document.getElementsByClassName("matches");

        for(let i=0; i<monthHeaders.length; i++)
        {
            monthHeaders[i].addEventListener("click", function(){
               matches[i].classList.toggle("visible");
               arrowIcons[i].classList.toggle("open");
            });
        }

        matches[5].classList.toggle("visible");
        arrowIcons[5].classList.toggle("open");
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Kalender | FC Los Tigres</title>
                    <meta name="description" content="Kalender met een overzicht van alle matchen van FC Los Tigres." />
                </Helmet>
                <Navigation />
                <main className="container">
                    <div className="calendar-wrapper">
                        <h1>Kalender</h1>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>September</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="24" day="dinsdag" month="september" score="5-1" team="De Ronnies" lost />
                                <PlayedMatch number="30" day="maandag" month="september" score="4-8" team="Pink Panthers" lost />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Oktober</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="13" day="zondag" month="oktober" score="10-2" team="'t Klikske" lost />
                                <PlayedMatch number="20" day="zondag" month="oktober" score="4-11" team="JJ Koningshooikt" lost />
                                <PlayedMatch number="27" day="zondag" month="oktober" score="5-1" team="Dynamo Lier" lost />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>November</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="10" day="zondag" month="november" score="2-11" team="Forza Juno" lost />
                                <PlayedMatch number="24" day="zondag" month="november" score="5-4" team="Hoekske Tanks" lost />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>December</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="1" day="zondag" month="december" score="11-6" team="Ontmoeting" won />
                                <PlayedMatch number="9" day="maandag" month="december" score="10-6" team="Dynamo Gestel" lost />
                                <PlayedMatch number="22" day="zondag" month="december" score="1-3" team="NC2" lost />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Januari</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="17" day="vrijdag" month="januari" score="20-0" team="La Tirette" lost />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Februari</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <PlayedMatch number="16" day="zondag" month="februari" score="9-5" team="Pink Panthers" lost />
                                <FutureMatch number="23" day="zondag" month="februari" time="17:30" team="'t Klikske" />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Maart</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <FutureMatch number="1" day="zondag" month="maart" time="18:30" team="JJ Koningshooikt" />
                                <FutureMatch number="8" day="zondag" month="maart" time="18:00" team="Dynamo Lier" />
                                <FutureMatch number="22" day="zondag" month="maart" time="17:30" team="Ontmoeting" />
                                <FutureMatch number="29" day="zondag" month="maart" time="18:30" team="La Tirette" />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>April</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <FutureMatch number="3" day="vrijdag" month="april" time="21:00" team="Hoekske Tanks" />
                                <FutureMatch number="20" day="maandag" month="april" time="22:00" team="Dynamo Gestel" />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Mei</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <FutureMatch number="3" day="zondag" month="mei" time="20:30" team="NC2" />
                                <FutureMatch number="5" day="dinsdag" month="mei" time="22:00" team="Lier United" />
                                <FutureMatch number="24" day="zondag" month="mei" time="17:30" team="Forza Juno" />
                                <FutureMatch number="26" day="dinsdag" month="mei" time="22:00" team="Lier United" />
                            </div>
                            <span className="d-line"></span>
                        </div>
                        <div className="calendar-month">
                            <div className="month-header">
                                <h3>Juni</h3>
                                <img src={Arrow} alt="Open calendar month button" className="arrow-icon" />
                            </div>
                            <div className="matches">
                                <FutureMatch number="7" day="zondag" month="juni" time="20:30" team="De Ronnies" />
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }
}

export default Calendar;