import React from 'react';
import Button from '../../atoms/Button/Button';
import { Link } from 'react-router-dom';

const teamsTable = (props) => (
    <div className="teams-table home-table">
        <h2>Klassement</h2>
        <table>
            <thead>
                <tr className="table-head-row">
                    <th></th>
                    <th colSpan="3">Ploeg</th>
                    <th>Punten</th>
                </tr>
            </thead>
            <tbody>
            <tr className="table-body-row">
                    <td>8</td>
                    <td colSpan="3">Lier United</td>
                    <td>12</td>
                </tr>
                <tr className="table-body-row">
                    <td>9</td>
                    <td colSpan="3">NC²</td>
                    <td>10</td>
                </tr>
                <tr className="table-body-row">
                    <td>10</td>
                    <td colSpan="3">Dynamo Gestel</td>
                    <td>10</td>
                </tr>
                <tr className="table-body-row">
                    <td>11</td>
                    <td colSpan="3">Hoekske Tanks</td>
                    <td>6</td>
                </tr>
                <tr className="table-body-row los-tigres">
                    <td>12</td>
                    <td colSpan="3">Los Tigres</td>
                    <td>3</td>
                </tr>
                <tr className="table-body-row">
                    <td>13</td>
                    <td colSpan="3">Ontmoeting</td>
                    <td>3</td>
                </tr>
            </tbody>
        </table>
        <Link to="/klassement"><Button text="Volledig klassement" /></Link>
    </div> 
);


export default teamsTable;