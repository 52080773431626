import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import TopLogo from '../../../assets/images/linksboven.svg';

const desktopNavigation = () => (
    <div className="desktop-navigation">
        <Link exact="true" to="/" className="logo-wrapper">
            <img src={TopLogo} alt="Los Tigres wide logo" className="los-tigres-logo" />
        </Link>
        <div className="container">
                <nav className="nav-links">
                    <NavLink exact to="/" className="nav-link">Home</NavLink>
                    <NavLink to="/kalender" className="nav-link">Kalender</NavLink>
                    <NavLink to="/contact" className="nav-link">Contact</NavLink>
                    <NavLink to="/topschuttersstand" className="nav-link">Topschuttersstand</NavLink>
                    <NavLink to="/klassement" className="nav-link">Klassement</NavLink>
                </nav>
        </div>
    </div>
);

export default desktopNavigation;