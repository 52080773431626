import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import TopLogo from '../../../assets/images/linksboven.svg';
import Logo from '../../../assets/images/logo.png';

class MobileNavigation extends Component {
    state = {
        reloader: false
    };

    checkReload = (clickedRoute) => {
        if(clickedRoute === window.location.pathname) {
            window.location.reload();
        }
    };

    render() {
        let navLinks = null;

        if(this.props.show) {
            navLinks = (
                <nav className="mobile-nav-links nav-links">
                    <NavLink to="/" className="nav-link">
                        <span onClick={() => this.checkReload("/")}>Home</span>
                    </NavLink>
                    <NavLink to="/kalender" className="nav-link">
                        <span onClick={() => this.checkReload("/kalender")}>Kalender</span>
                    </NavLink>
                    <NavLink to="/contact" className="nav-link">
                        <span onClick={() => this.checkReload("/contact")}>Contact</span>
                    </NavLink>
                    <NavLink to="/topschuttersstand" className="nav-link">
                        <span onClick={() => this.checkReload("/topschuttersstand")}>Topschuttersstand</span>
                    </NavLink>
                    <NavLink to="/klassement" className="nav-link">
                        <span onClick={() => this.checkReload("/klassement")}>Klassement</span>
                    </NavLink>
                    <img className="mobile-logo-badge" src={Logo} alt="Los Tigres badge" />
                </nav>
            );
        } else {
            navLinks = null;
        }

        return (
            <div className="mobile-nav-container">
                <div className="mobile-navigation">
                    <Link exact="true" to="/" className="home-link"><img src={TopLogo} alt="Los Tigres wide logo" className="los-tigres-logo" /></Link>
                    <div onClick={this.props.burgerClick} id="burger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {navLinks}
            </div>
        );
    }
}

export default MobileNavigation;