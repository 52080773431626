import React, { Component } from 'react';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';

class Navigation extends Component {
    constructor() {
        super();
        if( window.innerWidth < 1200)
        {
            this.state = {
                isMobile: true,
                showMobileLinks: false
            };
        } else {
            this.state = {
                isMobile: false,
                showMobileLinks: false
            };
        }
    }

    handleWindowResize = () => {
        this.setState({ isMobile: window.innerWidth < 1200 });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        if(document.body.classList.contains("mobile-open")) {
            document.body.classList.remove("mobile-open");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    burgerHandler = () => {
        this.setState({showMobileLinks: !this.state.showMobileLinks});
        document.body.classList.toggle("mobile-open");
        document.getElementById("burger").classList.toggle("clicked");
    }

    render () {
        let showNav = null;

        if(this.state.isMobile === true) {
            showNav = (<MobileNavigation burgerClick={this.burgerHandler} show={this.state.showMobileLinks} />);
        } else {
            showNav = (<DesktopNavigation />);
        }

        return (
            <div className="navigation">
                { showNav }
            </div>
        );
    }
}

export default Navigation;