import React from 'react';
import Logo from '../../../assets/images/logo.png';
import VanDessel from '../../../assets/images/LogoVD.png';

const footer = () => (
    <footer className="footer">
        <img src={Logo} alt="Los Tigres club badge" className="badge-logo" />
        <p className="zaal-info">
            <span>Sporthal 't Stapveld</span>
            <span>Pastorijstraat 62, 2590 Berlaar</span>
        </p>
        <a target="_blank" rel="noopener noreferrer" href="https://www.vandessel.be">
            <img src={VanDessel} alt="Van Dessel Insurance logo" className="vandessel" />
        </a>
        <p className="foot-note">
            <span>© 2019 - FC Los Tigres</span>

                <span>Development - <a target="_blank" rel="noopener noreferrer" href="https://jochenmeyvisch.com">Jochen Meyvisch</a></span>
                <span>
                    Design - <a target="_blank" rel="noopener noreferrer" href="https://timomeyvisch.com">Timo Meyvisch</a>
                </span>

        </p>
    </footer>
);

export default footer;