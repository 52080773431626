import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from '../../../axios-messages';

class Basic extends Component {
  state = {
    formSendSuccess: false,
    formSendFail: false
  }
  render() {
    let formSendSuccessMessage = null;
    if(this.state.formSendSuccess === true) {
      formSendSuccessMessage = <span className="form-message form-success">Uw bericht is succesvol verzonden.</span>
    }

    let formSendFailMessage = null;
    if(this.state.formSendFail === true) {
      formSendFailMessage = <span className="form-message form-fail">Er was een probleem met het verzenden.</span>
    }

    return (
      <div className="contact-form">
        <h1>Contacteer ons</h1>
        {formSendSuccessMessage}
        {formSendFailMessage}
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ name: '', email: '', message: '' }}

            validate={values => {
            let errors = {};

            if (!values.email) {
              errors.email = "Gelieve 'E-mailadres' in te vullen.";
              console.log("empty");
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Ongeldig e-mailadres!';
            }

            if (!values.name) {
                errors.name = "Gelieve 'Naam en voornaam' in te vullen.";
            }

            if (!values.message) {
                errors.message = "Gelieve 'Bericht' in te vullen.";
            }

            return errors;
          }}
          
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            
            axios.post('/messages.json', values)
                .then(response => {
                  resetForm();
                  this.setState({ formSendSuccess: true, formSendFail: false });
                })
                .catch(error => this.setState({ formSendFail: true, formSendSuccess: false }));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
                <div className="form-element">
                  <label htmlFor="name">Naam en voornaam</label>
                  <Field type="text" name="name" id="name" placeholder="Naam en voornaam" />
                  <ErrorMessage name="name" component="div" className="error-message" />
                </div>
                
                <div className="form-element">
                  <label htmlFor="email">E-mailadres</label>
                  <Field type="email" name="email" id="email" placeholder="bv. naam@gmail.com" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>

                <div className="form-element">
                  <label htmlFor="message">Bericht</label>
                  <Field name="message" component="textarea" id="message" placeholder="Waar gaat het over?" />
                  <ErrorMessage name="message" component="div" className="error-message" />
                </div>

                <div><button type="submit" className="main-button" disabled={isSubmitting}>Verzenden</button></div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default Basic;