import React from 'react';

const goalsTable = () => (
    <div className="teams-table topscorers">
        <h1>Topschuttersstand</h1>
        <table>
            <thead>
                <tr className="table-head-row">
                    <th></th>
                    <th colSpan="3">Speler</th>
                    <th>Goals</th>
                </tr>
            </thead>
            <tbody>
                <tr className="table-body-row first-place">
                    <td>1</td>
                    <td colSpan="3">Toby Van Roy</td>
                    <td>14</td>
                </tr>
                <tr className="table-body-row">
                    <td>2</td>
                    <td colSpan="3">Lenn De Vos</td>
                    <td>12</td>
                </tr>
                <tr className="table-body-row">
                    <td>3</td>
                    <td colSpan="3">Timo Meyvisch</td>
                    <td>6</td>
                </tr>
                <tr className="table-body-row">
                    <td>4</td>
                    <td colSpan="3">Maarten Willems</td>
                    <td>3</td>
                </tr>
                <tr className="table-body-row">
                    <td>5</td>
                    <td colSpan="3">Lennert De Backer</td>
                    <td>2</td>
                </tr>
                <tr className="table-body-row">
                    <td>6</td>
                    <td colSpan="3">Anthony Gabriels</td>
                    <td>2</td>
                </tr>
                <tr className="table-body-row">
                    <td>8</td>
                    <td colSpan="3">Jorne Guldentops</td>
                    <td>1</td>
                </tr>
                <tr className="table-body-row">
                    <td>7</td>
                    <td colSpan="3">Jochen Meyvisch</td>
                    <td>0</td>
                </tr>
                <tr className="table-body-row">
                    <td>9</td>
                    <td colSpan="3">Wouter Thys</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
);

export default goalsTable;